import * as React from "react"
import { graphql } from 'gatsby'

import IndexPageTemplate from "../components/pageTemplates/indexTemplate";

// markup
const IndexPageEn = ({pageContext, data}) => {
 pageContext.locale = 'en' 
    return (
      <IndexPageTemplate pageContext={pageContext}
                         data={data}
                         title='Brandeis Consulting - SAP Training from the Professionals'
                         excperpt='Relevant SAP knowledge from the practice for your project! From the author of the book "SQLScript for SAP HANA"'/>   
    )
  }
  
  export default IndexPageEn

  export const query = graphql` 
query Trainings_de {
  allMdx(
    filter: {frontmatter: {Type: {eq: "Training"}
                           hide: {ne: true }}}
  ) {
    nodes {
      frontmatter {
        Excerpt
        Title
        Slogan 
        Slug
        Locale
        cssClass
        Prio
        Duration
        Level
        Events{
          StartDate
        }
        Image{
          childImageSharp {
            gatsbyImageData(width: 840, height: 400)
          }
        }
      }
    }
  }
}
`